import React, { useState } from 'react';
import './App.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import Logo from './logo.png'; // Add your logo image here

function App() {
  const [language, setLanguage] = useState('en');

  const handleLanguageChange = () => {
    setLanguage((prevLang) => (prevLang === 'en' ? 'it' : 'en'));
  };

  return (
    <div className="App">
      <NavBar handleLanguageChange={handleLanguageChange} language={language} />
      <header className="App-header" id="home">
        <img src={Logo} className="App-logo" alt="logo" />
        <h1>{language === 'en' ? 'Welcome to BLARO' : 'Benvenuti a BLARO'}</h1>
        <p>{language === 'en' ? 'Creating mobile games that engage and entertain.' : 'Creiamo giochi per dispositivi mobili che coinvolgono e divertono.'}</p>
      </header>
      <main>
        <WhoWeAre language={language} />
        <WhatWeDo language={language} />
        {/* <EmailSubscription language={language} /> */}
      </main>
      <Footer language={language} />
    </div>
  );
}

function NavBar({ handleLanguageChange, language }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className="nav-bar">
      <div className="nav-container">
        <div className="nav-logo" onClick={closeMenu}>
          <img src={Logo} alt="BLARO" className="nav-logo-img" />
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={`nav-links ${menuOpen ? 'nav-links-active' : ''}`}>
          <li><a href="#home" onClick={closeMenu}>Home</a></li>
          <li><a href="#who-we-are" onClick={closeMenu}>Who We Are</a></li>
          <li><a href="#what-we-do" onClick={closeMenu}>What We Do</a></li>
          <li><a href="#subscribe" onClick={closeMenu}>Subscribe</a></li>
          <li className="language-selector">
            <img 
              src={language === 'en' 
                ? 'https://www.countryflags.com/wp-content/uploads/italy-flag-png-xl.png'
                : 'https://www.countryflags.com/wp-content/uploads/united-states-of-america-flag-png-xl.png'}
              alt={language === 'en' ? 'Italian' : 'English'} 
              onClick={() => {handleLanguageChange(); closeMenu();}} 
            />
          </li>
        </ul>
      </div>
    </nav>
  );
}

function WhoWeAre({ language }) {
  return (
    <section className="who-we-are rounded-section" id="who-we-are">
      <h2>{language === 'en' ? 'Who We Are' : 'Chi Siamo'}</h2>
      <p>
        {language === 'en'
          ? 'BLARO is a passionate team of mobile game developers committed to creating engaging and fun games for players of all ages. Our mission is to deliver high-quality gaming experiences that keep players coming back for more. We believe in the power of play and strive to make games that are not only entertaining but also bring people together.'
          : 'BLARO è un team appassionato di sviluppatori di giochi per dispositivi mobili impegnato nella creazione di giochi coinvolgenti e divertenti per giocatori di tutte le età. La nostra missione è offrire esperienze di gioco di alta qualità che tengano i giocatori a tornare per di più. Crediamo nel potere del gioco e ci sforziamo di realizzare giochi che non solo intrattengano, ma che anche uniscano le persone.'
        }
      </p>
    </section>
  );
}

function WhatWeDo({ language }) {
  return (
    <section className="what-we-do rounded-section" id="what-we-do">
      <h2>{language === 'en' ? 'What We Do' : 'Cosa Facciamo'}</h2>
      <p>
        {language === 'en'
          ? 'At BLARO, we specialize in developing mobile games that are both fun and challenging. Our games are designed to be accessible to everyone, from casual gamers to hardcore enthusiasts. We use the latest technology and innovative game design to create immersive experiences that players can enjoy anytime, anywhere. Join us on our journey to bring joy and excitement to the world, one game at a time.'
          : 'In BLARO, ci specializziamo nello sviluppo di giochi per dispositivi mobili che sono sia divertenti che impegnativi. I nostri giochi sono progettati per essere accessibili a tutti, dai giocatori occasionali agli appassionati. Utilizziamo le ultime tecnologie e un design di gioco innovativo per creare esperienze coinvolgenti che i giocatori possono godere in qualsiasi momento e ovunque. Unisciti a noi nel nostro viaggio per portare gioia ed emozioni nel mondo, un gioco alla volta.'
        }
      </p>
    </section>
  );
}

function EmailSubscription({ language }) {
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const result = await response.json();
      setMessage(result.message);
    } catch (error) {
      setMessage('Failed to subscribe. Please try again.');
    }
  };

  return (
    <section className="email-subscription rounded-section" id="subscribe">
      <h2>{language === 'en' ? 'Subscribe to Our Newsletter' : 'Iscriviti alla nostra Newsletter'}</h2>
      <p>{language === 'en' ? 'Stay updated with the latest news and releases from BLARO. Enter your email below to subscribe to our newsletter.' : 'Rimani aggiornato con le ultime novità e rilasci da BLARO. Inserisci la tua email qui sotto per iscriverti alla nostra newsletter.'}</p>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder={language === 'en' ? 'Enter your email' : 'Inserisci la tua email'}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">{language === 'en' ? 'Subscribe' : 'Iscriviti'}</button>
      </form>
      {message && <p>{message}</p>}
    </section>
  );
}

function Footer({ language }) {
  return (
    <footer className="footer rounded-section">
      <p>© 2024 BLARO. {language === 'en' ? 'All rights reserved.' : 'Tutti i diritti riservati.'}</p>
      <p>{language === 'en' ? 'Contact us: info@blarogames.com' : 'Contattaci: info@blarogames.com'}</p>
      <div className="footer-links">
        <a href="/PrivacyPolicy.html" target="_blank" rel="noopener noreferrer">
          {language === 'en' ? 'Privacy Policy' : 'Politica sulla Privacy'}
        </a>
        |
        <a href="/TermsAndConditions.html" target="_blank" rel="noopener noreferrer">
          {language === 'en' ? 'Terms and Conditions' : 'Termini e Condizioni'}
        </a>
      </div>
    </footer>
  );
}

export default App;
